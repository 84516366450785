import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApiListing from "../../../../Api/ApiListing";
import { ListingFullDTO, SocialLinksDTO } from "../../../../Api/Model";
import { selectSocialLinks } from "../../../../Store/App/selectors";
import { selectUser } from "../../../../Store/User/selectors";
import { listingModifiedAction, updateWizardStepAction } from "../../../../Store/Wizard/actions";
import { selectAllowEdit, selectListing } from "../../../../Store/Wizard/selectors";
import ScoopUtil from "../../../../Util/ScoopUtil";
import { deepEqual } from "../../../../Util/Util";
import Loading from "../../../UI/Loading";
import WizardGuide from "../../WizardGuide";
import WizardHeader from "../../WizardHeader";
import WizardMain from "../../WizardMain";
import WizardSidebar from "../../WizardSidebar";
import { WizardStep } from "../../WizardSteps";
import BackForwardButtons from "../BackForwardButtons";
import { FormContactsValues } from "../form-values";
import ContactInformationForm from "./ContactsForm";

const mapValues = (listing: ListingFullDTO, socialLinks: SocialLinksDTO[]): FormContactsValues => {
    const sl1 = socialLinks.find((t) => t.pkSocialLinkID === listing.details.fkSocialLink1);
    const sl2 = socialLinks.find((t) => t.pkSocialLinkID === listing.details.fkSocialLink2);
    const sl3 = socialLinks.find((t) => t.pkSocialLinkID === listing.details.fkSocialLink3);

    let values: FormContactsValues = {
        phoneNumber: listing.details.txtTelephone || "",
        email: listing.details.txtEmail || "",
        website: listing.details.txtWeb || "",
        bookingLink: listing.details.txtBookingLink || "",
        affiliateLink: listing.details.txtAffiliateLink || "",
        facebookUrl: "",
        twitterUrl: "",
        instagramUrl: "",
        linkedinUrl: "",
        pintrestUrl: "",
        otherUrl: "",
    };
    setSocialLink(values, sl1, listing.details.txtSocialLink1);
    setSocialLink(values, sl2, listing.details.txtSocialLink2);
    setSocialLink(values, sl3, listing.details.txtSocialLink3);
    return values;
};

const setSocialLink = (values: FormContactsValues, type?: SocialLinksDTO, value?: string) => {
    if (!type || !value) return;
    switch (type.socialLinkName) {
        case "Facebook":
            values.facebookUrl = value;
            break;
        case "Twitter":
            values.twitterUrl = value;
            break;
        case "Instagram":
            values.instagramUrl = value;
            break;
        case "LinkedIn":
            values.linkedinUrl = value;
            break;
        case "Pintrest":
            values.pintrestUrl = value;
            break;
        case "Other":
            values.otherUrl = value;
            break;
    }
};

interface Props {}

const ContactInformationStep = (props: Props) => {
    const dispatch = useDispatch();
    let params = useParams<{ id: any; licenseId: any }>();
    const listing = useSelector(selectListing);
    const socialLinks = useSelector(selectSocialLinks);
    const allowEdit = useSelector(selectAllowEdit);
    const user = useSelector(selectUser);

    const [origValues, setOrigValues] = useState<FormContactsValues>(undefined);
    const [values, setValues] = useState<FormContactsValues>(undefined);
    const [formValid, setFormValid] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (listing && socialLinks) {
            const mapped = mapValues(listing, socialLinks);
            setOrigValues(mapped);
            setValues({ ...mapped });
            setFormValid(true);
        }
    }, [listing, socialLinks]);

    useEffect(() => {
        if(listing && user) {
            setIsAdmin(user.userLevel === 2);
        }
    }, [user, listing])

    const generatePromise = (): Promise<ListingFullDTO> => {
        if (!formValid) return Promise.reject("Form is not valid");
        if (deepEqual(origValues, values)) return undefined;

        const {
            email,
            phoneNumber,
            website,
            bookingLink,
            affiliateLink,
            facebookUrl,
            twitterUrl,
            instagramUrl,
            linkedinUrl,
            otherUrl,
            pintrestUrl,
        } = values;

        var sl: { id: number; value: string }[] = [];
        const facebookId = socialLinks.find((t) => t.socialLinkName === "Facebook").pkSocialLinkID;
        const twitterId = socialLinks.find((t) => t.socialLinkName === "Twitter").pkSocialLinkID;
        const instagramId = socialLinks.find((t) => t.socialLinkName === "Instagram").pkSocialLinkID;
        const linkedinId = socialLinks.find((t) => t.socialLinkName === "LinkedIn").pkSocialLinkID;
        const otherId = socialLinks.find((t) => t.socialLinkName === "Other").pkSocialLinkID;
        const pintrestId = socialLinks.find((t) => t.socialLinkName === "Pinterest").pkSocialLinkID;

        if (facebookUrl.length > 0) sl.push({ id: facebookId, value: facebookUrl });
        if (twitterUrl.length > 0) sl.push({ id: twitterId, value: twitterUrl });
        if (linkedinUrl.length > 0) sl.push({ id: linkedinId, value: linkedinUrl });
        if (instagramUrl.length > 0) sl.push({ id: instagramId, value: instagramUrl });
        if (pintrestUrl.length > 0) sl.push({ id: pintrestId, value: pintrestUrl });
        if (otherUrl.length > 0) sl.push({ id: otherId, value: otherUrl });
        const linkId1 = sl[0] ? sl[0].id : 0;
        const link1 = sl[0] ? sl[0].value : "";
        const linkId2 = sl[1] ? sl[1].id : 0;
        const link2 = sl[1] ? sl[1].value : "";
        const linkId3 = sl[2] ? sl[2].id : 0;
        const link3 = sl[2] ? sl[2].value : "";

        return ApiListing.updateListingContacts(
            listing.details.pkListing,
            phoneNumber,
            email,
            website,
            bookingLink,
            affiliateLink,
            linkId1,
            link1,
            linkId2,
            link2,
            linkId3,
            link3,
            params.licenseId
        );
    };

    const onWizardStepChange = (step: WizardStep) => {
        if (formValid) {
            let promise = generatePromise();
            dispatch(updateWizardStepAction(step, promise));
        }
    };

    const onValuesChange = (values: FormContactsValues, formValid: boolean) => {
        setValues(values);
        setFormValid(formValid);
    };

    const onSaveHook = (): Promise<any> => {
        return generatePromise();
    };

    useEffect(() => {
        if (values && origValues) {
            dispatch(listingModifiedAction(!deepEqual(origValues, values)));
        }
    }, [values, origValues, dispatch]);

    return (
        <>
            <WizardHeader disabled={!!!formValid} backToListingsHook={onSaveHook} />
            <WizardSidebar disableAll={!!!formValid} onSaveHook={onSaveHook} />
            <WizardMain>
                {values ? (
                    <ContactInformationForm
                        disabled={!allowEdit}
                        values={values}
                        onChange={onValuesChange}
                        showBookingLink={listing?.details.bookingLinkAllowDisplay}
                        isAdmin={isAdmin}
                        showAffiliateLink={listing?.details.affiliateLinkAllowDisplay}
                    />
                ) : (
                    <Loading />
                )}

                <BackForwardButtons
                    disabled={!!!formValid}
                    onClickPrevious={() =>
                        onWizardStepChange(ScoopUtil.allowApprove(listing) ? WizardStep.Approval : WizardStep.Details)
                    }
                    onClickNext={() => onWizardStepChange(WizardStep.Location)}
                />
            </WizardMain>
            <WizardGuide step={WizardStep.ContactInformation} />
        </>
    );
};

export default ContactInformationStep;
